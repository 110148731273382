<template>
  <div class="home">
    <div class="filter-wrapper">
      <div>
        <call-input
          v-model="dataSearch"
          type-input="search"
          placeholder="Search"
          @input="onSearch">
          <template slot="prefix">
            <font-awesome-icon
              :icon="dataIconSearch" />
          </template>
        </call-input>

        <v-select
          v-model="dataSelectLastDayValue"
          :options="dataSelectOptionsLastDay"
          :filterable="false"
          :searchable="false"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All"
          :class="['call-select', dataSelectLastDayValue ? 'call-select--value' : null]"
          @input="$_ajax({ reset: true })">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
        </v-select>

        <v-select
          v-if="!computedIsOperator"
          v-model="dataSelectOperatorValue"
          :options="dataSelectOptionsOperator"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All Operators"
          :class="['call-select', dataSelectOperatorValue ? 'call-select--value' : null]"
          @input="onSelectTeamsAndRole">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя Operator
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>

        <popover
          v-model="dataShowPopover"
          trigger="click">
          <div style="padding: 16px">
            <span class="filter-wrapper__label">Status</span>
            <call-checked-closed-opened-all v-model="dataFilterOpenedClosed" />
            <span class="filter-wrapper__label">Closed Type</span>
            <v-select
              v-model="typeClosing"
              :options="typeClosingList"
              :filterable="false"
              :searchable="false"
              :components="{Deselect: dataComponentDeselect}"
              placeholder="All"
              style="margin-bottom: 16px"
              :class="['tasks__popover-select', 'call-select', typeClosing ? 'call-select--value' : null]">
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <font-awesome-icon :icon="dataIconDown" />
                </span>
              </template>
            </v-select>
            <span class="filter-wrapper__label">Result</span>
            <div
              v-for="list in filterPhone"
              :key="list.name"
              :class="{'is-active': list.active}"
              style="background-color: transparent;margin-bottom: 8px"
              class="call-phone-checkbox-filter">
              <input
                :id="'subscribeNews' + list.name"
                v-model="list.active"
                type="checkbox">
              <label
                class="call-phone-checkbox-filter__title"
                :for="'subscribeNews' + list.name">
                {{ list.name }}
              </label>
            </div>
            <div style="display: flex; min-width: 300px; margin-top: 16px">
              <call-button
                type="danger"
                plain
                full
                style="margin: 0px 2px"
                :icon="dataIconClose"
                @click="clickFilterCancel()">
                &nbsp;Clear Filter
              </call-button>
              <call-button
                type="success"
                full
                style="margin: 0px 2px"
                :icon="dataIconCheck"
                @click="clickFilterSend()">
                &nbsp;Filter
              </call-button>
            </div>
          </div>
          <template #reference>
            <call-button
              :icon="dataIconFilter"
              style="position: relative">
              Filter
              <call-badge
                v-if="computedFilterCount > 0"
                class="call-filter-badge"
                type="danger">
                {{ computedFilterCount }}
              </call-badge>
            </call-button>
          </template>
        </popover>
      </div>
      <div>
        <call-pagination
          :obj-pagination="dataObjPagination"
          @on-pagination="$_ajax" />
        <call-button
          v-if="(computedIsAdmin || computedIsModerator) && computedProjectIsActive"
          :icon="dataAddIcon"
          type="success"
          @click="clickShowAddTask()">
          Add Tasks
        </call-button>
      </div>
    </div>
    <div class="table-wrapper">
      <b-table
        striped
        hover
        no-local-sorting
        :show-empty="isLoaded"
        :fields="dataMixinTaskFields"
        :items="dataTaskList"
        @sort-changed="onSortTabel"
        @row-hovered="onHover"
        @row-clicked="(value) => onActions(value, 'view')"
        @row-unhovered="onUnHover">
        <template #empty>
          <div class="table-empty">
            <font-awesome-icon
              class="table-empty__icon"
              :icon="dataIconList" />
            <h4 class="table-empty__text">
              No avaliable data
            </h4>
          </div>
        </template>
        <template #head(auto_closed_free)>
          <span class="success">
            Closed
          </span>
          Free
        </template>
        <template #head(auto_closed_assigned)>
          <span class="success">
            Closed
          </span>
          Assigned
        </template>
        <template #cell(isBlocked)="data">
          <font-awesome-icon
            v-if="!data.item.closed_by"
            :icon="dataIconClock" />
          <font-awesome-icon
            v-else
            class="tabel-icon--green"
            :icon="dataIconCheck" />
        </template>
        <template #cell(actions)="data">
          <call-table-tasks-actions
            v-show="data.item.actions"
            :closed="!data.item.closed_by"
            :operator="!data.item.operator_id"
            :time="!data.item.execute_before"
            @on-action="(value) => onActions(data.item, value)" />
        </template>
        <!-- <template #cell(player_birthday)="data">
          {{ data.item.player_birthday | dateBrithday }}
        </template> -->
        <template #cell(auto_closed_free)="data">
          <span :class="[data.item.auto_closed_free && 'success']">
            <font-awesome-icon
              :icon="data.item.auto_closed_free ? dataIconCheck : dataIconClose" />
          </span>
        </template>
        <template #cell(auto_closed_assigned)="data">
          <span :class="[data.item.auto_closed_assigned && 'success']">
            <font-awesome-icon
              :icon="data.item.auto_closed_assigned ? dataIconCheck : dataIconClose" />
          </span>
        </template>
        <template #cell(kpi)="data">
          <span :class="[data.item.kpi && 'success']">
            <font-awesome-icon
              :icon="data.item.kpi ? dataIconCheck : dataIconClose" />
          </span>
        </template>
        <template #cell(execute_before)="data">
          <call-badge-table
            v-if="data.item.execute_before"
            :type="methodTypeScheduled(data.item.execute_before, data.item.closed_by)">
            {{ data.item.execute_before | dateFilter }}
          </call-badge-table>
        </template>
        <template #cell(comment_count)="data">
          {{ data.item.comment_count }} <font-awesome-icon
            :class="data.item.comment_count > 0 ? 'tabel-icon--green' : 'tabel-icon--white'"
            :icon="dataIconComment" />
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | dateFilter }}
        </template>
      </b-table>
    </div>
    <call-modal-add-tasks
      v-model="dataShowAddTask"
      :project-id="computedProjectId"
      @on-update="$_ajax" />
    <call-re-assign
      v-model="dataShowReAssign"
      :title="dataModalTitle"
      :team-id="computedProjectTeamId"
      :task-id="dataIdUpdateTask"
      :operator-id="dataModalOption"
      @on-update="$_ajax" />
    <call-re-sheduled
      v-model="dataShowReSchedule"
      :title="dataModalTitle"
      :task-id="dataIdUpdateTask"
      :time-schedule="dataModalOption"
      @on-update="$_ajax" />
    <call-close-task
      v-model="dataShowCloseTask"
      :task-id="dataIdUpdateTask"
      @on-update="$_ajax" />
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';
import { faCheck, faClock, faComments, faFilter, faPlus, faSearch, faTimes, faList } from '@fortawesome/free-solid-svg-icons';
import CallInput from '../../../components/common/CallInput';
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import CallPagination from '../../../components/desktop/CallPagination';
import taskTable from '../../../mixins/table/taskTable';
import { PROJECTS_GET, TASKS_GET_ALL, USERS_GET_ALL } from '../../../store/types/actions.types';
import helper, { tabelCell } from '../../../service/helper';
import selectOperators from '../../../mixins/select/selectOperators';
import CallCheckedClosedOpenedAll from '../../../components/common/CallCheckedClosedOpenedAll';
import CallBadgeTable from '../../../components/common/CallBadgeTable';
import CallTableTasksActions from '../../../components/desktop/CallTableTasksActions';
import CallReSheduled from '../../../components/common/modals/CallReSheduled';
import CallCloseTask from '../../../components/common/modals/CallCloseTask';
import CallReAssign from '../../../components/common/modals/CallReAssign';
import { ID_ROLE_OPERATOR } from '../../../service/consts';
import CallModalAddTasks from '../../../components/common/modals/CallAddTasks';
import CallButton from '../../../components/common/CallButton';
import Popover from 'vant/lib/popover';
import 'vant/lib/popover/style';
import CallBadge from '@/components/common/CallBadge';
import { SET_ALL } from '@/store/types/mutations.types';
import selectTasksPeriod from '../../../mixins/select/selectTasksPeriod';

const _currentDate = Date.now();

export default {
  name: 'PageTask',
  components: {
    CallBadge,
    CallButton,
    CallModalAddTasks,
    CallReAssign,
    CallCloseTask,
    CallReSheduled,
    CallTableTasksActions,
    CallBadgeTable,
    CallCheckedClosedOpenedAll,
    CallPagination,
    CallInput,
    BTable,
    Popover
  },
  mixins: [taskTable, selectOperators, selectTasksPeriod],
  data () {
    return {
      dataAddIcon: faPlus,
      dataIconSearch: faSearch,
      dataIconClock: faClock,
      dataIconCheck: faCheck,
      dataIconComment: faComments,
      dataIconFilter: faFilter,
      dataShowAddTask: false,
      dataShowUpdateTask: false,
      dataIdUpdateTask: null,
      dataShowReSchedule: false,
      dataShowReAssign: false,
      dataShowCloseTask: false,
      dataShowPopover: false,
      dataIconClose: faTimes,
      dataIconList: faList,
      filterPhone: [
        {
          active: false,
          name: 'Successful',
          key: 'success'
        },
        {
          active: false,
          name: 'Cancelled',
          key: 'cancelled'
        },
        {
          active: false,
          name: 'No Answer',
          key: 'noanswer'
        },
        {
          active: false,
          name: 'Rejected',
          key: 'rejected'
        }
      ],
      dataModalOption: null,
      dataModalTitle: null,
      dataFilterOpenedClosed: null,
      dataTaskList: [],
      dataSearch: null,
      dataDesc: null,
      dataOrderBy: null,
      dataProjectOptions: {},
      dataObjPagination: {},
      typeClosing: null,
      typeClosingList: [
        {
          key: null,
          label: 'All'
        },
        {
          key: 'auto_closed_free',
          label: 'Auto Closed Free'
        },
        {
          key: 'auto_closed_assigned',
          label: 'Auto Closed Assigned'
        },
        {
          key: 'kpi',
          label: 'KPI'
        }
      ],
      isLoaded: false
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin',
      computedIsOperator: 'auth/isOperator',
      computedIsModerator: 'auth/isModerator',
      computedCurrentUser: 'auth/currentUser'
    }),
    computedFilterCount () {
      const closedFilterEnabed = this.typeClosing ? 1 : 0;
      const tabFilterEnabled = this.dataFilterOpenedClosed ? 1 : 0;
      return this.filterPhone.filter(item => item.active).length + tabFilterEnabled + closedFilterEnabed;
    },
    computedProjectId () {
      return this.$route.params?.projectId;
    },
    computedProjectIsActive () {
      // eslint-disable-next-line camelcase
      return this.dataProjectOptions?.is_active;
    },
    computedProjectTeamId () {
      // eslint-disable-next-line camelcase
      return this.dataProjectOptions?.team_id;
    },
    computedIsVip () {
      return this.dataProjectOptions?.vip;
    }
  },
  // watch: {
  //   dataFilterOpenedClosed () {
  //     this.$_ajax({ reset: true });
  //   }
  // },
  async created () {
    this.isLoaded = false;
    this.$_ajax = ((options = {}) => {
      let _page = 0;
      let _limit = tabelCell();

      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(JSON.parse(this.$route.query[_filterKey]));

          const _arrFilter = [
            'type',
            'operator_ids',
            'search',
            'desc',
            'orderBy',
            'limit',
            'page',
            'call_statuses',
            'period',
            'closing_type'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                case 'type':
                  this.dataFilterOpenedClosed = options[_key];
                  break;
                case 'search':
                  this.dataSearch = options[_key];
                  break;
                case 'operator_ids':
                  this.dataSelectOperatorValue = options[_key];
                  break;
                case 'desc':
                  this.dataDesc = options[_key];
                  break;
                case 'orderBy':
                  this.dataOrderBy = options[_key];
                  break;
                case 'limit':
                  _limit = options[_key];
                  break;
                case 'page':
                  _page = options[_key];
                  break;
                case 'call_statuses':
                  this.filterPhone = options[_key];
                  break;
                case 'period':
                  this.dataSelectLastDayValue = options[_key];
                  break;
                case 'closing_type':
                  this.typeClosing = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async ({ search = this.dataSearch, desc = this.dataDesc, orderBy = this.dataOrderBy, limit = null, page = null, reset = false } = options) => {
        if (!this.dataSelectLastDayValue) {
          this.dataSelectLastDayValue = { ...this.dataSelectLastDayValueDefault };
        }

        if (!helper.isEmpty(page) && page !== _page) {
          _page = page;
        }

        if (!helper.isEmpty(limit) && limit !== _limit) {
          _limit = limit;
        }

        if (reset) {
          _page = 0;
        }

        const { payload: { list = [] }, misc: { paging = {} } } = await this.$store.dispatch(`task/${TASKS_GET_ALL}`, {
          params: {
            project_ids: [this.computedProjectId],
            search,

            ...(this.dataSelectOperatorValue?.id === 'without_operator'
              ? { without_operator: true }
              : { operator_ids: this.computedIsOperator ? [this.computedCurrentUser?.id] : [this.dataSelectOperatorValue?.id] }
            ),
            type: this.dataFilterOpenedClosed,
            desc,
            order_by: orderBy,
            limit: _limit,
            page: _page,
            call_statuses: this.filterPhone.reduce((itemA, itemB) => {
              if (itemB.active) {
                itemA.push(itemB.key);
              }
              return itemA;
            }, []),
            period: this.dataSelectLastDayValue?.key,
            closing_type: this.typeClosing?.key
          }
        });
        this.isLoaded = true;
        this.dataObjPagination = paging;
        this.dataTaskList = list.map((item) => {
          item.actions = false;
          return item;
        });

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            ...(this.$route.query || {}),
            [_filterKey]: JSON.stringify(helper.clearObject({
              type: this.dataFilterOpenedClosed,
              operator_ids: this.dataSelectOperatorValue,
              search,
              desc,
              orderBy,
              limit: _limit,
              page: _page,
              call_statuses: this.filterPhone,
              period: this.dataSelectLastDayValue,
              closing_type: this.typeClosing
            })),
            isVip: this.computedIsVip
          }
        }).catch(() => {});

        options = {};
      };
    })();

    const [{ project = {} }] = await Promise.all([
      this.$store.dispatch(`project/${PROJECTS_GET}`, {
        projectId: this.computedProjectId
      }),
      this.$_ajax()
    ]);

    this.dataProjectOptions = project;

    this.$nextTick(async _ => {
      const { payload: { list: listUser = [] } } = await this.$store.dispatch(`user/${USERS_GET_ALL}`, {
        params: {
          team_ids: [this.computedProjectTeamId],
          role: ID_ROLE_OPERATOR,
          short: true
        }
      });

      this.dataSelectOptionsOperator = [...listUser, { id: 'without_operator', name: 'Without operator' }];
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          ...(this.$route.query || {}),
          isVip: this.computedIsVip
        }
      }).catch(() => {});
    });
  },

  mounted () {
    if (helper.isEmpty(this.$route.params?.projectId)) {
      this.$router.go(-1);
    }
  },

  methods: {
    clickFilterSend () {
      this.$store.commit(`mobile/${SET_ALL}`, {
        data: this.filterPhone,
        key: 'filterPhone'
      });
      this.dataShowPopover = false;
      this.$_ajax({ reset: true });
    },
    clickFilterCancel () {
      this.dataFilterOpenedClosed = null;
      this.typeClosing = null;
      this.filterPhone = this.filterPhone.map(item => {
        item.active = false;
        return item;
      });
      this.dataShowPopover = false;
      this.$_ajax({ reset: true });
    },
    clickShowAddTask () {
      this.dataShowAddTask = true;
    },
    onSortTabel ({ sortBy, sortDesc }) {
      this.dataDesc = sortDesc;
      this.dataOrderBy = sortBy;
      this.$_ajax();
    },
    onSearch: debounce(function () {
      this.$_ajax({ reset: true });
    }, 1200),
    onSelectTeamsAndRole () {
      this.$_ajax({ reset: true });
    },
    onActions ({ id: taskId, is_blocked: isBlocked, execute_before: executeBefore, operator_id: operatorId = null, player_id: playerId = 0 }, action) {
      this.dataIdUpdateTask = taskId;
      switch (action) {
        case 're-asigned':
          this.dataModalTitle = helper.isEmpty(operatorId) ? 'Assign' : 'Re Assign';
          this.dataModalOption = operatorId;
          this.dataShowReAssign = true;
          break;
        case 're-sheduled':
          this.dataModalTitle = helper.isEmpty(executeBefore) ? 'Schedule' : 'Re Schedule';
          this.dataModalOption = executeBefore;
          this.dataShowReSchedule = true;
          break;
        case 'view':
          this.$router.push({
            name: 'ProjectTasksDetail',
            params: {
              taskId,
              projectId: this.computedProjectId
            },
            query: {
              ...(this.$route.query || {}),
              taskName: 'ID: ' + playerId
            }
          });
          break;
        case 'closed':
          this.dataShowCloseTask = true;
          break;
      }
    },
    methodTypeScheduled (date, closed) {
      if (!closed) {
        if (_currentDate > +new Date(date)) {
          return 'danger';
        } else if (_currentDate + 3600 * 24 * 2 * 1000 < +new Date(date)) {
          return 'success';
        } else {
          return 'warning';
        }
      } else {
        return 'success';
      }
    }
  }
};
</script>
